import * as React from 'react';
import Header from './header';
import Footer from './footer';
import BackgroundImage from 'gatsby-background-image';
import { Helmet } from 'react-helmet';
import * as _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import { MessageFormatElement } from 'react-intl';

export interface TextBlock {
  header: string | MessageFormatElement[];
  message?: JSX.Element;
}

interface TextPageProps {
  locale: string;
  title: string | MessageFormatElement[];
  textBlocks?: TextBlock[];
  otherElements?: JSX.Element | null;
}

const TextPage = (props: TextPageProps): JSX.Element => {
  const {placeholderImage} = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "header-background.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }`);
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{props.title}</title>
      </Helmet>

      <Header/>

      <div id="top-container" className={`ui big container ${(props.locale === "en" ? "text-ltr" : "text-rtl")}`}>
        <BackgroundImage Tag="div" {...bgImage}>
          <div className="header-background">
            <h1>{props.title}</h1>
          </div>
        </BackgroundImage>
        {
          props.textBlocks &&
          <div className="ui text container">
            {_.map(props.textBlocks, block => (
              <div key={`${block.header}`} className="text-block">
                <h3 className="ui header">{block.header}</h3>
                {block.message}
              </div>
            ))}
          </div>
        }
      </div>

      {props.otherElements}

      <Footer/>
    </>
  );
};

export default TextPage;